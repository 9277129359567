import { ChainId, JSBI, Percent, Token, WKLC } from '@kalycoinproject/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected } from '../connectors'

export const ROUTER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.TESTNET]: '0x7fD3173Eef473F64AD4553169D6d334d42Df1d95',
  [ChainId.KALYCHAIN]: '0x183F288BF7EEBe1A3f318F4681dF4a70ef32B2f3'
}

export const LANDING_PAGE = 'https://kalyswap.io/'
export const BRIDGE_PAGE = 'https://bridge.kalychain.io'
export const IDO_PAGE = 'https://kalypad.kalyswap.io'
export const KLC_PAGE = 'https://staking.kalychain.io'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const KSWAP: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, ZERO_ADDRESS, 18, 'KSWAP', 'KalySwap'),
  [ChainId.KALYCHAIN]: new Token(
    ChainId.KALYCHAIN,
    '0xCC93b84cEed74Dc28c746b7697d6fA477ffFf65a',
    18,
    'KSWAP',
    'KalySwap'
  )
}

export const ETH: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, ZERO_ADDRESS, 18, 'ETH', 'Ether'),
  [ChainId.KALYCHAIN]: new Token(ChainId.KALYCHAIN, '0x8dB8052b1adBe2fC4464dC04746fa068f19DCeD0', 18, 'ETH', 'Ether')
}

export const USDT: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, ZERO_ADDRESS, 18, 'USDT', 'Tether USD'),
  [ChainId.KALYCHAIN]: new Token(
    ChainId.KALYCHAIN,
    '0x37540F0cC489088c01631138Da2E32cF406B83B8',
    18,
    'USDT',
    'Tether USD'
  )
}

export const USDC: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, ZERO_ADDRESS, 18, 'USDC', 'USD Coin'),
  [ChainId.KALYCHAIN]: new Token(
    ChainId.KALYCHAIN,
    '0xFF97974fcEfD3C6E04C7a6f3C4FA971c4A18F762',
    18,
    'USDC',
    'USD Coin'
  )
}

export const WBTC: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, ZERO_ADDRESS, 18, 'WBTC', 'Wrapped Bitcoin'),
  [ChainId.KALYCHAIN]: new Token(
    ChainId.KALYCHAIN,
    '0xD0731970CCFEc3EB25c16e956F0b6902Fba75B69',
    18,
    'WBTC',
    'Wrapped Bitcoin'
  )
}

export const DAI: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, ZERO_ADDRESS, 18, 'DAI', 'Dai Stablecoin'),
  [ChainId.KALYCHAIN]: new Token(
    ChainId.KALYCHAIN,
    '0xC2AFb6EFca0F6b10f3da80bEc20Dc8DE0DdB689D',
    18,
    'DAI',
    'Dai Stablecoin'
  )
}

export const BNB: { [chainId in ChainId]: Token } = {
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, ZERO_ADDRESS, 18, 'BNB', 'Binance Coin'),
  [ChainId.KALYCHAIN]: new Token(
    ChainId.KALYCHAIN,
    '0x2F4fB121ad39B71A4516aaa88a5C7ea926f5350f',
    18,
    'BNB',
    'Binance Coin'
  )
}

export const AIRDROP_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.TESTNET]: ZERO_ADDRESS,
  [ChainId.KALYCHAIN]: '0x289C080aB271eD5b7F8A14406DB5D768F45f8BFB'
}

const WKLC_ONLY: ChainTokenList = {
  [ChainId.TESTNET]: [WKLC[ChainId.TESTNET]],
  [ChainId.KALYCHAIN]: [WKLC[ChainId.KALYCHAIN]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WKLC_ONLY,
  [ChainId.KALYCHAIN]: [...WKLC_ONLY[ChainId.KALYCHAIN]]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.KALYCHAIN]: {}
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WKLC_ONLY,
  [ChainId.KALYCHAIN]: [...WKLC_ONLY[ChainId.KALYCHAIN]]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WKLC_ONLY,
  [ChainId.KALYCHAIN]: [...WKLC_ONLY[ChainId.KALYCHAIN]]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.KALYCHAIN]: []
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 60 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 60

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
